import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              '\nBASE_URL = "/";\nIS_LOGGEDIN = "0";\nSITE_URL = BASE_URL;\nLANG_BASE_URL = "/";\n'
          }}
        />

        <script
          async
          type="text/javascript"
          src="/static/js/generated_js/958a58972bf272cd5ead1fe8b4a225e1.js?=1588071973"
        />
        <script async typ="text/javascript" src="/static/js/generated_js/jquery-migrate-1.4.1-min.js" />
        <div id="layout">
          <div className="head">
            <div className="inner">
              <a className="logo" href="/">
                <img src="/static/i/layout/New_Logo.png" alt="VISINE" />
              </a>
              <div className="menuHolder">
                <ul id="menu">
                  <li className="mainMenu">
                    <a href="/">Főoldal</a>
                  </li>
                  <li className="mainMenu">
                    <a href="/voros-szemre/">Vörös szemre</a>
                  </li>
                  <li className="mainMenu">
                    <a href="/termekeink/visine-classic/">
                      VISINE<sup>®</sup> Rapid
                    </a>
                  </li>
                  <li className="mainMenu">
                    <a href="/szemtorna/">Szemtorna</a>
                  </li>
                  <li className="mainMenu">
                    <a href="/kapcsolat/">Kapcsolat</a>
                  </li>
                </ul>
              </div>
              <div className="clear" />
            </div>
          </div>
          <div id="content" className="content">
            <div className="subPage" id="cookiePolicyPage">
              <div className="inner">
                <h1>Cookie szabályzat</h1>
                <p>
                  A jelen Cookie szabályzat célja ismertetni a felhasználókkal a cookie-k és egyéb követési technológiák (mint például a gif-ek, web jelek, stb.) segítségével megvalósuló adat- és információgyűjtésre vonatkozó gyakorlatunkat.
                </p>
                <p>
                  Az említett technológiák a működésben-funkcionalitásban a következő szerepet tölthetik be:
                </p>
                <ul>
                  <li>Segítik a böngészést,</li>
                  <li>
                    Segítenek a felhasználónak regisztrálni a rendezvényeinkre, bejelentkezni és visszajelzést szolgáltatni,
                  </li>
                  <li>
                    Elemzik, hogyan használják termékeinket, szolgáltatásainkat és alkalmazásainkat,
                  </li>
                  <li>
                    Segítik reklámozási és marketing munkánkat (beleértve a viselkedés-alapú célzott reklámozást),
                  </li>
                  <li>
                    Harmadik fél által forgalmazott tartalmat kínálnak (például közösségi média tartalmat).
                  </li>
                </ul>
                <p>
                  Az alábbiakban a felhasznált cookie-k tételes és részletes felsorolását találja. A cookie-kat a következő típusokba soroljuk:
                </p>
                <ul>
                  <li>Feltétlenül szükséges cookie-k</li>
                  <li>
                    Elemzési cookie-k
                  </li>
                  <li>
                    Funkcionális cookie-k
                  </li>
                  <li>
                    Reklám cookie-k
                  </li>
                </ul>
                <p>
                  A cookie-kat kizárólag az Ön beleegyezését követően helyezzük el, melyet a cooke banneren vagy a preferencia központon keresztül tehet meg. Kivételt képeznek az a cookie-k, amelyek elhelyezését a vonatkozó jogszabály külön felhasználói hozzájárulás nélkül is engedélyezi. A cookie beállításokat bármikor megváltoztathatja a cookie típusa szerint (kivételt képeznek a webhely rendeltetésszerű működéséhez elengedhetetlenül szükséges cookie-k). Ehhez kattintson az alábbi “cookie beállítások” gombra:
                </p>
                <div><button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie-beállítások</button></div>
                <br />
                <p>&nbsp;</p>
                <div>
                  <div id="ot-sdk-cookie-policy"></div>
                </div>
                <h2>Kapcsolat más weboldalakhoz</h2>
                <p>
                  A weboldalon előfordulhatnak más oldalakra mutató utalások vagy linkek. Kérjük, vegyék figyelembe, hogy nem áll módunkban ellenőrizni más weboldalak cookie-jait vagy követési technológiáit és ez a Cookie szabályzat nem vonatkozik azokra a weboldalakra.
                </p>
                <h2>Elérhetőségeink</h2>
                <p>
                  A Cookie szabályzatra vagy a weboldal adatgyűjtési gyakorlatára vonatkozó kérdéseinek, megjegyzéseinek vagy problémáinak közlésére használja az Adatvédelmi Tájékoztatóban megadott elérhetőséget
                </p>
                <h2>Jelen Cookie szabályzat módosítása</h2>
                <p>
                  Amennyiben módosul a jelen Cookie szabályzat, a módosított szabályzatot ezen a weboldalon tesszük közzé. Jelen Cookie szabályzat legutóbbi frissítésének dátuma 2020.05.11.
                </p>
              </div>
            </div>
            <div className="clear" />
          </div>
          <div className="footer">
            <div className="inner">
              <div className="productStatus">
                <div className="contentInner">
                  <div className="footerInner">
                    VISINE<sup>®</sup> Rapid 0,5 mg/ml oldatos szemcsepp. Vény
                    nélkül kapható, tetrizolin-hidroklorid hatóanyagú gyógyszer.
                  </div>
                </div>
              </div>
              <div className="footerMenu">
                <ul>
                  <li>
                    <a href="/jogi-nyilatkozat/">Jogi nyilatkozat</a>
                  </li>
                  <li>
                    <a href="/adatvedelmi-szabalyzat/">
                      Adatvédelmi szabályzat
                    </a>
                  </li>
                  <li>
                    <a href="/oldalterkep/">Oldaltérkép</a>
                  </li>
                  <li>
                    <a href="/cookie-szabalyzat/">Cookie szabályzat</a>
                  </li>
                  <li>
                    <a href="#" id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie-beállítások</a>
                  </li>
                </ul>
              </div>
              <div className="johnson">
                <div className="contentInner">
                  <div className="footerInner">
                    <p className="bigger">©JNTL CONSUMER HEALTH (HUNGARY) KFT., 2023. 1112 Budapest, BudapestOne Center Balatoni út 2. a. ép. 1. em.</p>
                    <p>
                      Ezt az oldalt a JNTL Consumer Health (Hungary) Kft. tette közzé, és
                      felel annak tartalmáért.
                    </p>
                    <p>
                      Ez az oldal magyarországi felhasználók számára készült.
                    </p>
                    <p>Utoljára frissítve: 2023.02.01.</p>
                    <p>HU-VI-2200008</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="risks">
            <div className="contentInner">
              <div className="footerInner">
                A kockázatokról és a mellékhatásokról olvassa el a
                betegtájékoztatót, vagy kérdezze meg kezelőorvosát,
                gyógyszerészét!
              </div>
            </div>
          </div>
        </div>


      </div>
    );
  }
}

export default Page;
